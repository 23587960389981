import React, { useEffect, useState } from 'react';
import Cards from './Cards';
import { Container, Grid, Box, CircularProgress, Alert } from '@mui/material';

const Offerings = () => {
  const [offerings, setOfferings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOfferings = async () => {
      try {
        setLoading(true);
        const response = await fetch('https://superteacher.co.in/api/offerings/students/cbse-subjects');
        if (!response.ok) {
          throw new Error('Failed to fetch offerings');
        }
        const data = await response.json();
        setOfferings(data);
      } catch (error) {
        console.error("Error fetching offerings:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOfferings();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress sx={{ color: '#3a96d7' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="xl" sx={{ py: 4 }}>
        <Alert severity="error">
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        py: 4,
        pl: { xs: 4, sm: 6, md: 8 },
        pr: { xs: 8, sm: 10, md: 12 },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center" // Center align items in the flex container
        sx={{ pt: 2, pb: 2 }} // Add padding top and bottom of 10px
      >
        <Grid
          container
          spacing={4} // Increased gap between cards
          sx={{
            minHeight: '100vh',
            maxWidth: '1920px',
            mx: 'auto',
            justifyContent: { xs: 'center', sm: 'flex-start' }, // Center on mobile, left-align on larger screens
          }}
        >
          {offerings.map((offering) => (
            <Grid
              item
              key={offering._id}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', sm: 'flex-start' } // Center cards on mobile, left-align on larger screens
              }}
            >
              <Cards offering={offering} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Offerings;
