import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";

const ColorCardSection = () => {
  const [offerings, setOfferings] = useState([]);

  useEffect(() => {
    const fetchOfferings = async () => {
      try {
        const response = await fetch('https://superteacher.co.in/api/home/offeringcards');
        const data = await response.json();
        setOfferings(data);
      } catch (error) {
        console.error('Error fetching offerings:', error);
      }
    };
    
    fetchOfferings();
  }, []);

  const cardColors = ['#3a96d7', '#fecc00', '#78b833'];
  const starIcon = "https://www.freeiconspng.com/thumbs/star-icon/yellow-star-icon-21.png";

  return (
    <Box>
      <Grid container justifyContent="center">
        {cardColors.map((color, index) => (
          <Grid 
            key={index}
            item 
          
            xs={12} 
            md={4} 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center',
              position: 'relative',
              mt: { xs: 10, md: 6 }
            }}
          >
            
            
            <Box 
              bgcolor='' 
              maxWidth={500} 
              minHeight={500} 
              width="100%"
              sx={{
                transition: 'all 0.3s ease-in-out',
                '&:hover': {              
                  transform: 'scale(1.05)',
                  boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
                  zIndex: 1
                }
              }}
            >

<Box
              sx={{
                position: 'absolute',
                top: { xs: -70, md: -50 },
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 2, // Increased z-index to stay above card during hover
                bgcolor: color,
                height: '100px',
                width: '100px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Avatar src={starIcon} sx={{ width: 50, height: 50 }} />
            </Box>

              <Box 
                bgcolor={color}
                height='100%' 
                width="87%" 
                borderRadius='10px'
                p={3}
                pt={8}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  height='100%' 
                  width="100%"
                  spacing={1}
                >
                  <Grid item>
                    <Box
                      component="img"
                      src={offerings[index]?.imageUrl}
                      alt={offerings[index]?.courseName}
                      sx={{
                        width: '100%',
                        height: 'auto',
                        maxHeight: '200px',
                        objectFit: 'contain',
                        borderRadius:'5px'
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" align="center" color="white" gutterBottom>
                      {offerings[index]?.courseName || 'Loading...'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" align="justify" color="white">
                      {offerings[index]?.cardsDescription || 'Loading...'}
                    </Typography>
                  </Grid>
                  <Grid item container spacing={2} justifyContent="center">
                    <Grid item>
                      <Button 
                        variant="contained" 
                        sx={{ bgcolor: color, color:'white',
                          '&:hover': {              
                            bgcolor: '#ffffffb5', color: color }
                         }}
                        href="/offerings/schools"
                      >
                        Know More
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button 
                        variant="contained" 
                        sx={{ bgcolor: color, color:'white',
                          '&:hover': {              
                            bgcolor: '#ffffffb5', color: color }
                         }}
                        href={offerings[index]?.enrollNow}
                      >
                        Enroll Now
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ mb: { xs: 8, md: 0 } }} />
                </Grid>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ColorCardSection;