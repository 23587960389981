import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, CircularProgress, Alert } from '@mui/material';
import CareerCards from './CareerCards';
import axios from 'axios';

const CareerList = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://superteacher.co.in/api/career');
        setJobs(response.data);
        setError(null);
      } catch (err) {
        console.error("Error fetching career data:", err);
        setError('Failed to load career opportunities. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress sx={{ color: '#3a96d7' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          alignItems: 'center',
          width: '100%'
        }}
      >
        {jobs.length > 0 ? (
          jobs.map((job) => (
            <CareerCards
              key={job._id}
              jobRole={job.JobRole}
              location={job.location}
              knowMoreLink={`/career/${job._id}`}
            />
          ))
        ) : (
          <Typography 
            variant="h6" 
            sx={{ 
              textAlign: 'center',
              color: '#666'
            }}
          >
            No job openings available at the moment.
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default CareerList;