import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Container,
  Stack,
  CircularProgress,
  Alert,
  Divider
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';

const EventCard = ({ venue, details, organizer, joinLink }) => {
    return (
      <Card
        sx={{
          width: '100%',
          maxWidth: { xs: '100%', sm: 350 },
          height: 500,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          position: 'relative',
          bgcolor: '#fff',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
          },
          transition: 'all 0.3s ease',
        }}
      >
        {/* Top Accent Bar */}
        <Box sx={{ height: 4, bgcolor: '#3a96d7', width: '100%' }} />
  
        <CardContent 
          sx={{ 
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            gap: 2
          }}
        >
          {/* Venue Section */}
          <Box>
            <Stack 
              direction="row" 
              spacing={1} 
              alignItems="center" 
              sx={{ mb: 1 }}
            >
              <LocationOnIcon sx={{ color: '#3a96d7' }} />
              <Typography 
                sx={{ 
                  fontWeight: 600,
                  color: '#2c3e50',
                  fontSize: '1rem'
                }}
              >
                Venue
              </Typography>
            </Stack>
            <Typography 
              sx={{ 
                pl: 4,
                color: '#4a5568',
                fontSize: '0.95rem',
                lineHeight: 1.5
              }}
            >
              {venue}
            </Typography>
          </Box>
  
          <Divider />
  
          {/* Details Section */}
          <Box sx={{ flex: 1 }}>
            <Stack 
              direction="row" 
              spacing={1} 
              alignItems="center" 
              sx={{ mb: 1 }}
            >
              <InfoIcon sx={{ color: '#3a96d7' }} />
              <Typography 
                sx={{ 
                  fontWeight: 600,
                  color: '#2c3e50',
                  fontSize: '1rem'
                }}
              >
                Event Details
              </Typography>
            </Stack>
            <Typography 
              sx={{ 
                pl: 4,
                color: '#4a5568',
                fontSize: '0.95rem',
                lineHeight: 1.6,
                maxHeight: '150px',
                overflow: 'auto',
                scrollbarWidth: 'thin',
                '&::-webkit-scrollbar': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#cbd5e0',
                  borderRadius: '3px',
                }
              }}
            >
              {details}
            </Typography>
          </Box>
  
          <Divider />
  
          {/* Organizer Section */}
          <Box>
            <Stack 
              direction="row" 
              spacing={1} 
              alignItems="center" 
              sx={{ mb: 1 }}
            >
              <PersonIcon sx={{ color: '#3a96d7' }} />
              <Typography 
                sx={{ 
                  fontWeight: 600,
                  color: '#2c3e50',
                  fontSize: '1rem'
                }}
              >
                Organizer
              </Typography>
            </Stack>
            <Typography 
              sx={{ 
                pl: 4,
                color: '#4a5568',
                fontSize: '0.95rem',
                lineHeight: 1.5
              }}
            >
              {organizer}
            </Typography>
          </Box>
  
          {/* Join Button */}
          <Box sx={{ mt: 'auto', pt: 2 }}>
            <Button
              variant="contained"
              href={joinLink}
              fullWidth
              sx={{
                bgcolor: '#fecc00',
                color: 'white',
                py: 1.5,
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 500,
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(254, 204, 0, 0.2)',
                '&:hover': {
                  bgcolor: '#e3b800',
                  boxShadow: '0 4px 8px rgba(254, 204, 0, 0.3)',
                }
              }}
            >
              Join Event
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  };

const EventList = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://superteacher.co.in/api/eventcard');
        setEvents(response.data);
      } catch (err) {
        console.error("Error fetching events:", err);
        setError('Failed to load events. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress sx={{ color: '#3a96d7' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Container sx={{ py: 4 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <Box 
    sx={{ 
      display: 'flex',
      justifyContent: 'flex-end',
      py: 4,
      pr: { xs: 4, sm: 6, md: 8, lg: 12 },
      width: '100%'
    }}
  >
    <Stack 
      spacing={4} 
      alignItems="flex-end"
      sx={{ 
        width: { xs: '100%', sm: 'auto' },
        mr: { xs: 2, sm: 4, md: 6 }  
      }}
    >

      {events?.length > 0 ? (
        events.map((event, index) => (
          <EventCard
            key={event._id || index}
            venue={event.venue}
            details={event.details}
            organizer={event.organizer}
            joinLink={event.joinLink}
          />
        ))
      ) : (
        <Typography 
          variant="h6" 
          sx={{ 
            textAlign: 'center',
            color: '#666'
          }}
        >
          No upcoming events at the moment.
        </Typography>
      )}
    </Stack>
  </Box>
  );
};


export default EventList;