import { Box, Button, Card, Grid, Container, useTheme, useMediaQuery, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SwipeableViews from 'react-swipeable-views';
import { styled } from '@mui/material/styles';

// Styled components
const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(10),
  maxWidth: '1600px !important',
  backgroundColor: '#ffffff',
  margin: '0 auto',
  boxShadow: 'inset 0 0 20px rgba(0, 0, 0, 0.05)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(8, 2),
  },
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  maxWidth: '1400px',
  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    padding: '0 32px',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 12,
  height: 520, // Increased height
  width: '100%',
  maxWidth: 380, // Increased max-width
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
  border: '1px solid #e0e0e0',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  overflow: 'hidden',
  position: 'relative',
  margin: '0 auto',
  '&:hover': {
    boxShadow: '0 12px 24px rgba(0, 0, 0, 0.12)',
    transform: 'translateY(-6px)',
    '& .card-image': {
      transform: 'scale(1.05)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    height: 480,
    maxWidth: '100%',
  },
}));

const ImageWrapper = styled(Box)({
  height: 240, // Increased height
  overflow: 'hidden',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '40px',
    background: 'linear-gradient(to top, rgba(255,255,255,0.2), transparent)',
  },
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'transform 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
  },
});

const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
}));

const CourseTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.4rem',
  fontWeight: 700,
  color: '#1a1a1a',
  lineHeight: 1.4,
  marginBottom: theme.spacing(1),
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  height: '2.8em',
  fontFamily: "'Roboto', sans-serif",
}));

const CourseDescription = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: '#555555',
  lineHeight: 1.7,
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  height: '5.1em',
  fontFamily: "'Roboto', sans-serif",
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 4),
  borderTop: '1px solid #eaeaea',
  backgroundColor: '#ffffff',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#fecc00',
  color: '#1a1a1a',
  padding: theme.spacing(1.2, 4),
  borderRadius: 6,
  fontWeight: 600,
  textTransform: 'none',
  fontSize: '1rem',
  transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  fontFamily: "'Roboto', sans-serif",
  '&:hover': {
    backgroundColor: '#e6b800',
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
  },
}));

const DurationText = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  color: '#666666',
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  '&::before': {
    content: '""',
    display: 'inline-block',
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    backgroundColor: '#fecc00',
    marginRight: theme.spacing(1),
  },
}));

export default function CoursesAtHome() {
  const [activeStep, setActiveStep] = useState(0);
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const readyCourses = async () => {
      try {
        setIsLoading(true);
        const response = await fetch('https://superteacher.co.in/api/home/popular');
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        setCourses(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Error fetching courses:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    readyCourses();
  }, []);

  const CourseCard = ({ course }) => (
    <StyledCard>
      <ImageWrapper>
        <img 
          className="card-image"
          src={course.imageUrl} 
          alt={course.courseName}
        />
      </ImageWrapper>
      <ContentWrapper>
        <CourseTitle>
          {course.courseName}
        </CourseTitle>
        <CourseDescription>
          {course.cardsDescription}
        </CourseDescription>
      </ContentWrapper>
      <ButtonWrapper>
        <StyledButton 
          href="/offerings/teachers"
          disableElevation
        >
          Learn More
        </StyledButton>
        <DurationText>4 weeks</DurationText>
      </ButtonWrapper>
    </StyledCard>
  );

  if (isLoading) {
    return (
      <StyledContainer>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
          <Typography variant="h6" color="textSecondary">Loading courses...</Typography>
        </Box>
      </StyledContainer>
    );
  }

  if (error) {
    return (
      <StyledContainer>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
          <Typography variant="h6" color="error">Error loading courses: {error}</Typography>
        </Box>
      </StyledContainer>
    );
  }

  if (!courses.length) {
    return (
      <StyledContainer>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
          <Typography variant="h6" color="textSecondary">No courses available</Typography>
        </Box>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      {isMobile ? (
        <SwipeableViews
          enableMouseEvents
          index={activeStep}
          onChangeIndex={(step) => setActiveStep(step)}
          style={{ padding: '0 16px' }}
        >
          {courses.map((course, index) => (
            <Box key={course.courseId || index} sx={{ padding: '0 8px' }}>
              <CourseCard course={course} />
            </Box>
          ))}
        </SwipeableViews>
      ) : (
        <GridContainer 
          container 
          spacing={5}
          justifyContent="center"
          alignItems="stretch"
        >
          {courses.map((course, index) => (
            <Grid 
              item 
              xs={12} 
              sm={6} 
              md={4} 
              lg={4}
              key={course.courseId || index}
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <CourseCard course={course} />
            </Grid>
          ))}
        </GridContainer>
      )}
    </StyledContainer>
  );
}