import React, { useState, useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { styled } from '@mui/system';

const CarouselContainer = styled(Box)(() => ({
  overflow: 'hidden',
  width: '100%',
  height: 200,
  position: 'relative',
}));

const LogoContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPaused',
})(({ isPaused }) => ({
  display: 'flex',
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  height: '80%',
  animation: 'slide 30s linear infinite',
  animationPlayState: isPaused ? 'paused' : 'running',
  '@keyframes slide': {
    '0%': {
      transform: 'translateX(0%) translateY(-50%)',
    },
    '100%': {
      transform: 'translateX(-50%) translateY(-50%)',
    },
  },
}));

const LogoItem = styled(Box)(() => ({
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 50px',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.2)',
    zIndex: 1,
  },
}));

const Logo = styled('img')({
  maxHeight: '100%',
  width: 'auto',
  maxWidth: '100%',
  objectFit: 'contain',
});

export const GClients = () => {
  const [isPaused, setIsPaused] = useState(false);
  const [clients, setClients] = useState([]); // Initialize as empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch('https://superteacher.co.in/api/home/clients');
        if (!response.ok) {
          throw new Error('Failed to fetch clients');
        }
        const data = await response.json();
        // Ensure data is an array before setting it
        if (Array.isArray(data)) {
          setClients(data);
        } else if (data && Array.isArray(data.clients)) {
          setClients(data.clients);
        } else {
          // If data is not in expected format, set as empty array
          console.error('Received unexpected data format:', data);
          setClients([]);
        }
        setLoading(false);
      } catch (err) {
        console.error('Error fetching clients:', err);
        setError(err.message);
        setClients([]); // Ensure clients is an array even on error
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  if (loading) {
    return (
      <Box sx={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        Loading...
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        Error: {error}
      </Box>
    );
  }

  // Only proceed if we have clients
  if (!clients.length) {
    return (
      <Box sx={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        No clients to display
      </Box>
    );
  }

  // Make sure we have enough items for smooth scrolling
  const duplicatedClients = [...Array(4)].flatMap(() => clients);

  return (
    <Box sx={{ bgcolor: 'background.paper' }}>
      <Container maxWidth="lg">
        <CarouselContainer
          onMouseEnter={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
        >
          <LogoContainer isPaused={isPaused}>
            {duplicatedClients.map((client, index) => (
              <LogoItem key={`${client.name}-${index}`}>
                <Logo 
                  src={client.image}
                  alt={client.name}
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite loop
                    e.target.src = '/api/placeholder/200/200'; // Fallback image
                  }}
                />
              </LogoItem>
            ))}
          </LogoContainer>
        </CarouselContainer>
      </Container>
    </Box>
  );
};

export default GClients;